body {
    word-wrap: break-word;
}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}
.video {
    max-width: 800px;
}
#react-content {
    min-height: 100%;
}
.home-page-wrapper p {
    padding: 0;
    margin: 0;
}
