#footer .footer-wrapper {
    min-height: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#dartAvatar {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    display: flex;
}

#footer .ant-list-item {
    padding: 5px 25px;
}

#footer .ant-list-item a, #footer .ant-list-item div {
    color: #fff;
}

#footer .ant-list-item a:hover {
    color: gold;
}

#footer .ant-list-item-meta-description, #footer .ant-list-item-meta-title {
    display: flex;
}

#footer .ant-list-item-meta-title a {
    text-decoration: underline double gold ;
}