#navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

#navbar img, #navbar ul {
    display: flex;
    align-items: center;
    background-color: #000;
    color: #fff;
    font-weight: bold;
}

#navbar ul li:hover {
    color: gold;
}

#navbar #guildLogo {
    width: 50px;
    height: 50px;
    margin: 10px;
}

#navbar .ant-menu-item-selected {
    color: gold;
}

#navbar .ant-menu-item-selected:after {
    border-bottom-color: gold;
}

#navbar .ant-menu-item:hover:after {
    border-bottom-color: gold;
}

#navbar #discordLogo {
    width: 40px;
    height: 40px;
}

#navbar #youtubeLogo {
    height: 30px;
    width: 50px;
}

#navbar .menu-item {
    display: flex;
    align-items: center;
    font-size: 16px;
}

#navbar .menu-item a {
    color: #fff;
    text-decoration: none;
}

#navbar .menu-item-selected {
    border-bottom: 1px solid gold;
    padding-bottom: 5px;
}

#navbar .menu-item a:hover  {
    color: gold;
}

#navbar .ant-col {
    display: flex;
}

#navbar .menu-item > * {
    padding-left: 20px;
    padding-right: 20px;
}

.submenu-link {
    background-color: #000;
    color: white;
    font-size: 16px;
    border: none;
}

.submenu {
    position: relative;
    display: inline-block;
    padding-top: 25px;
    padding-bottom: 25px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border: 1px solid #fff;
    border-radius: 5px;
    top: 60px;
    text-align: right;
    right: 0px;
    min-width: 200px;
}

.dropdown-content a {
    color: #fff;
    padding: 20px 20px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    color: gold;
}

.submenu:hover .dropdown-content {
    display: block;
}

.submenu:hover .submenu-link {
    color: gold;
}