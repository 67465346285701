#lootSplitCalculator {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
}

#lootSplitCalculator .loot-split-row {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
}

#lootSplitCalculator #number-of-players-wrapper, #lootSplitCalculator #percentage-buyout-wrapper2 {
    padding-bottom: 10px;
}

#lootSplitCalculator #each-players-split-wrapper, #lootSplitCalculator #each-players-split-wrapper2 {
    padding-top: 10px;
}

#lootSplitCalculator #each-players-split-wrapper, #lootSplitCalculator #each-players-split-wrapper2 {
    border-top: 1px solid gold;
}

#lootSplitCalculator .loot-split-row input {
    height: 3em;
    font-weight: bold;
}

#lootSplitCalculator button {
    background: gold;
    border: gold;
    border-radius: 3px;
    font-weight: bold;
}

#lootSplitCalculator button:hover {
    background-color: #FFC400FF;
    color: black;
}

#lootSplitCalculator .ant-input-disabled {
    background-color: #fff;
    color: #000;
}

#lootSplit .ant-tabs-tab-btn {
    font-size: 20px;
}

#lootSplit #lootSplitWrapper {
    color: #fff;
    display: block;
    font-weight: bold;
    font-size: 20px;
}

#lootSplitTitle, h2 {
    margin-bottom: 5%;
}

#logoWrapper {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}

#lootSplit .charterRow {
    margin-left: 25px;
    line-height: 35px;
}

#lootSplit .ant-tabs .charterRow {
    color: #fff;
    font-size: 18px;
}

#lootSplit .ant-tabs-tab.ant-tabs-tab-active div {
    color: gold;
}

#lootSplit .ant-tabs-tab div {
    color: white;
    padding-left: 15px;
    padding-right: 15px
}

#lootSplit .ant-tabs-tab div:hover {
    color: gold;
}

#lootSplit .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: gold;
}

#lootSplit #copyIcon, #lootSplit #copyIcon2, #lootSplit #copyIcon3 {
    width: 25px;
    height: 25px;
    background-color: transparent;
}

body #lootSplit #checkMarkIcon, body #lootSplit #checkMarkIcon2, body #lootSplit .checkMarkIcon {
    width: 30px;
    height: 30px;
    right: 5px;
    background: gold;
    z-index: -100;
    border-radius: 5px;
    padding-left: 1px;
    padding-right: 1px
}

#lootSplit #each-players-split-wrapper, #lootSplit #each-players-split-wrapper2 {
    display: flex;
    position: relative;
}

#lootSplit #each-players-split-wrapper button, #lootSplit #each-players-split-wrapper2 button, #lootSplitCalculator #copyIconButton3, #lootSplit #checkMarkIcon, #lootSplit #checkMarkIcon2, #lootSplit .checkMarkIcon {
    position: absolute;
    top: 7px;
    right: 2px;
    background: transparent;
}

#lootSplit .inputWrapper {
    width: 65%;
    position: relative;
}

#lootSplit #copyIconButton, #lootSplit #copyIconButton2, #lootSplit #copyIconButton3 {
    cursor: pointer;
}

#lootSplit #partialButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#lootSplitCalculator .split-row-pair-wrapper {
    display: flex;
    align-items: center;
    min-width: 100px;
}

#lootSplitCalculator .split-row.loot-split-row {
    justify-content: space-around;
}

#lootSplitCalculator .split-input-wrapper {
    position: relative;
}

#lootSplitCalculator #splitRow1 {
    margin-top: 25px;
}

#lootSplitCalculator .split-row-pair-wrapper .ant-select {
    width: 100%;
}

#lootSplitCalculator .split-row-description {
    font-size: 17px;
}

#lootSplitCalculator .ant-space-vertical {
    width: 100%;
}

#lootSplitCalculator .ant-collapse-header {
    color: gold;
}

#lootSplitCalculator #switch-to-buyout-wrapper .inputWrapper {
    display: flex;
    justify-content: right;
}

#lootSplitCalculator #switch-to-buyout-wrapper {
    border-bottom: 1px solid gold;
}

#lootSplitCalculator #calculate-button-wrapper {
    margin-top: 50px;
}

#lootSplitCalculator #calculate-button-wrapper .inputWrapper {
    display: flex;
    justify-content: right;
}

