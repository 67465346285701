/* Summary CSS */

.content5-wrapper {
    background-color: #000;
    min-height: 720px;
    color: #fff;
}

#summary .title-h1 {
    color: #fff
}

.summaryBlurb {
    font-weight: bold;
    margin-top: 10px;
    text-align: left;
    line-height: 25px;
}

.summaryBlurbWrapper {
    margin-top: 15%;
}

.content5-wrapper .content5 >p {
    text-align: center;
}

.content5-wrapper .content5-img-wrapper {
    margin: 0 auto;
    left: 0;
    right: 0;
}

.content5-wrapper .content5-img-wrapper .block {
    margin-bottom: 24px;
}

.content5-wrapper .content5-img-wrapper .block .content5-block-content {
    display: block;
    background: #000;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border: none;
    color: #ccc;
    cursor: default;
    /*transition: box-shadow .3s , transform .3s;*/
    /*border-radius: 6px;*/
    /*border: 1px solid #e9e9e9;*/
    /*transform: translateY(0);*/
    /*transition: transform .3s @ease-out, box-shadow .3s @ease-out;*/
}

.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
    width: inherit;
    height: 160px;
    display: block;
    background: #000;
    padding: 5%;
}

.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
    width: 100%;
    line-height: 30px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}

.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {

    bottom: 0;
}
@media screen and (max-width: 767px) {
    .content5-wrapper {
        height: 2000px;
        overflow: hidden;
    }

    .content5-wrapper .content5 ul li {
        display: block;
        width: 100%;
        padding: 2%;
    }

    .content5-wrapper .content5 ul li span {
        height: 168px;
    }

    .content5-wrapper .content5 ul li p {
        position: relative;
        bottom: 0;
    }
}
