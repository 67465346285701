#callToAction.content5-wrapper {
    min-height: inherit;
    background: #000;
    color: #fff;
}

#callToAction .title-h1 {
    margin-bottom: 65px;
}

#callToAction .title-h1 .title {
    padding: 0 5px 5px 5px;
    color: #fff
}

#callToAction .title-content {
    font-size: 22px;
}

#callToAction button {
    background: gold;
    border: gold;
    border-radius: 3px;
    font-weight: bold;
    margin: 5% 5%;
}

#callToAction button:hover {
    background-color: #FFC400FF;
    color: black;
}